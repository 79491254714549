import React from 'react';
import { StyledButton } from './styles/StyledButton';

export function Button({buttonName, callback }: {
  buttonName: string;
  callback: () => void;  
}): JSX.Element {
  return (
    <StyledButton onClick={callback}>{buttonName}</StyledButton>
  )
}