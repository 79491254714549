import { StageData, Player, moveDir, Matrix, WordsOnBoard, Position, Word } from '../types/types';
import { STAGE_WIDTH } from './stageFunctions';
import { generateTwoThreePairs } from './wordLetterGeneration';
import { randomTetromino } from '../types/tetrominos';

function transposeMatrix<T>(matrix: Matrix<T>, dir: number): Matrix<T> {
  // Make the rows to become cols (transpose)
  const outputMatrix = matrix.map((_, index) => matrix.map(column => column[index]));
  // Reverse each row to get a rotaded matrix
  if (dir > 0) return outputMatrix.map(row => row.reverse());
  return outputMatrix.reverse();
};

export function rotatePlayer(prevPlayer: Player, stage: StageData, dir: number) {
  // rotates the tetromino clockwise only
  const clonedPlayer = structuredClone(prevPlayer);
  clonedPlayer.tetromino = transposeMatrix(clonedPlayer.tetromino, dir);
  clonedPlayer.letter = transposeMatrix(clonedPlayer.letter, dir)
  const pos = clonedPlayer.pos.x;
  let offset = 1;
  while (checkCollision(clonedPlayer, stage, { moveX: 0, moveY: 0 })) {
    clonedPlayer.pos.x += offset;
    offset = -(offset + (offset > 0 ? 1 : -1));
    if (offset > clonedPlayer.tetromino[0].length) {
      transposeMatrix(clonedPlayer.tetromino, -dir);
      transposeMatrix(clonedPlayer.letter, -dir);
      clonedPlayer.pos.x = pos;
      return prevPlayer;
    }
  }
  return clonedPlayer;
};

export function resetPlayer(wordsOnBoard: WordsOnBoard[]) {
  // created a new tetromino; this is triggered when the game starts
  // or when a tetromino collides and a new one is generated
  const tetroObject = randomTetromino();
  const newTetro = tetroObject.shape;
  const letterGrid = structuredClone(newTetro) as (string)[][];
  let idx = 0;
  let generatedLetters: string[] = [];
  generatedLetters = generatedLetters.concat(generateTwoThreePairs(tetroObject.nletters, wordsOnBoard).split(''))
  for (let i = 0; i < letterGrid.length; i++) {
    let row = letterGrid[i];
    for (let j = 0; j < row.length; j++) {
      if (row[j] !== 'Z') {
        letterGrid[i][j] = generatedLetters[idx]
        idx += 1
      }
    }
  }
  const newPlayer: Player = {
    pos: { x: STAGE_WIDTH / 2 - 2, y: 0 },
    tetromino: newTetro,
    collided: false,
    letter: letterGrid,
  }
  return newPlayer;
};

export function updatePlayerPos(prevPlayer: Player, { x, y }: Position, collided: boolean) {
  // changes tetromino's position: down, up, right, left 
  const updatedPlayer = {
    ...prevPlayer,
    pos: { x: (prevPlayer.pos.x + x), y: (prevPlayer.pos.y + y) },
    collided,
  }
  return updatedPlayer;
}

export const checkCollision = (player: Player, stage: StageData, { moveX, moveY }: moveDir) => {
  // Using for loops to be able to return (and break). Not possible with forEach
  for (let y = 0; y < player.tetromino.length; y += 1) {
    for (let x = 0; x < player.tetromino[y].length; x += 1) {
      // 1. Check that we're on an actual Tetromino cell
      if (player.tetromino[y][x] !== 'Z') {
        if (
          // 2. Check that our move is inside the game areas height (y)
          // That we're not go through bottom of the play area
          !stage[y + player.pos.y + moveY] ||
          // 3. Check that our move is inside the game areas width (x)
          !stage[y + player.pos.y + moveY][x + player.pos.x + moveX] ||
          // 4. Check that the cell wer'e moving to isn't set to clear
          stage[y + player.pos.y + moveY][x + player.pos.x + moveX][1] !==
          'clear'
        ) {
          return true;
        }
      }
    }
  }
  // 5. If everything above is false
  return false;
};