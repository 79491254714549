import styled from 'styled-components';
import React from 'react';
import Popup from 'reactjs-popup';

export const StyledRulesPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
  }
  // use your custom style for ".popup-content"
  &-content {
    background: #fffeea;
  }
`;