import React from 'react';
import { TetrominoTypeId } from '../types/tetrominos';
import { Letter } from '../types/types';
import { StyledNextTetrominoCell } from './styles/StyledNextTetrominoCell';

export function NextTetrominoCell({ type, letter, color }: {
  type: TetrominoTypeId,
  letter: Letter,
  color: string,
}): JSX.Element {
  return (
    <StyledNextTetrominoCell type={type} color={color}>{letter}</StyledNextTetrominoCell>
  )
}