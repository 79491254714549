import styled from 'styled-components';
import { TetrominoTypeId } from '../../types/tetrominos';

interface Props {
  color: string;
  type: TetrominoTypeId;
}

export const StyledNextTetrominoCell = styled.div<Props>`
  aspect-ratio: 1;
  background: rgba(${props => props.color}, 1);
  border: ${props => (props.type === 'Z' ? '0.005em solid' : '0.005em solid')};
  border-bottom-color: rgba(0, 0, 0, 1);
  border-right-color: rgba(0, 0, 0, 1);
  border-top-color: rgba(0, 0, 0, 1);
  border-left-color: rgba(0, 0, 0, 1);
  width: auto;
  color: black;
  font-size: 16px;
  text-align:center;
`
  ;