import React from 'react';
import { StyledDisplay } from './styles/StyledDisplay';


export function Display({ gameover, text } : {
    gameover: boolean;
    text: string;
}) : JSX.Element {
    return (
       <StyledDisplay gameover={gameover}> {text}</StyledDisplay>   
       )
}

