import styled from 'styled-components';

export const StyledButton = styled.button`
  align-self: stretch;
  align-items: center;
  padding: 0px;
  background-color: #F3CA20;
  justify-content: center;
  display: inline-flex;
  color: black;
  font-size: 15px; 
  width: 70px;
  height: 25px;
  word-wrap: break-word;
  border:0;
  outline: none;
`
;