import { useCallback, useState } from 'react';
import { TETROMINOS } from '../types/tetrominos';
import { Signal, GameEngine } from '../types/types';
import { createStage } from '../game_engine/stageFunctions';
import { handleSignal } from '../game_engine/gameEngineMain';
import { createTrie } from '../game_engine/Trie';

export function useGameEngine(): GameEngine {
	const [gameState, setGameState] = useState<GameEngine['gameState']>({
		stage: createStage(),
		player: {
			pos: { x: 0, y: 0 },
			tetromino: TETROMINOS['Z'].shape,
			collided: false,
			letter: structuredClone(TETROMINOS['Z'].shape),
		},
		score: 0,
		words: new Set(),
		level: {level: '', multiplier: 0},
		wordsOnBoard: [],
		wordsFound: [''],
		gameOver: false,
		trie: createTrie(),
		bonusWords: [],
		nextPlayer: {
			pos: { x: 0, y: 0 },
			tetromino: TETROMINOS['Z'].shape,
			collided: false,
			letter: structuredClone(TETROMINOS['Z'].shape),
		}
	});
	// TODO: thsi still causes the same bug, needs to fire one at a time and wait to finish
	const sendSignal = useCallback((signal: Signal) => {
		setGameState((currentState) => { return handleSignal(currentState, signal) });
	},
		[],
	);
	return {
		gameState,
		sendSignal
	};
}