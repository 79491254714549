import { WordCoordinates, StageCell, StageData, StageRow, TSFixme, WordsOnBoard, Player } from '../types/types';
import { Trie } from './Trie';
import { bfs } from './bfs';

export const STAGE_WIDTH = 12; //12
export const STAGE_HEIGHT = 18; //20

// tetromino id, merged|clear, letter
export const createStage: () => StageData =
	() => Array.from<StageRow, StageRow>(
		Array<StageRow>(STAGE_HEIGHT),
		() => Array<StageCell>(STAGE_WIDTH).fill(['Z', 'clear', '']),
	);


export const flushStage: (prevStage: StageData) => StageData = prevStage => {
	// flush the stage
	const newStage: StageData = prevStage.map(row =>
		row.map(cell => (cell[1] === 'clear' ? ['Z', 'clear', ''] : cell))
	);
	return newStage;
};

export const drawTetrominoOnStage = (player: Player, curStage: StageData) => {
	player.tetromino.forEach((row, y) => {
		row.forEach((value, x) => {
			if (value !== 'Z') {
				const allowedIndex = STAGE_HEIGHT - 1;
				if (y + player.pos.y > allowedIndex) {
					debugger;
				}
				curStage[y + player.pos.y][x + player.pos.x] = [
					value,
					`${player.collided ? 'merged' : 'clear'}`,
					player.letter[y][x] as string,
				];
			}
		});
	});
	return curStage;
};

export const findWordMatchesOnStage = (newStage: StageData, trie: Trie) => {
	const arr: (WordCoordinates)[] = [];
	const seen = {};
	for (let idx = newStage.length - 1; idx > 0; idx--) {
		const row = newStage[idx];
		for (let col = 0; col < row.length; col++) {
			// checks if a cell has a letter; if true
			if (row[col][0] !== 'Z') {
				bfs(newStage, '', col, idx, arr, seen, [], trie);
			}
		}
	}
	return arr;
};

export const clearMatchedWords = (arr: WordCoordinates[], stage: StageData) => {
	const stageEmpty: StageData = Array(stage.length).fill(undefined).map(() => Array(stage[0].length));
	const allCoordinates: TSFixme[] = [];
	const wordsFound: TSFixme[] = [];
	for (let i = 0; i < arr.length; i++) {
		wordsFound.push(arr[i].word)
		allCoordinates.push(...arr[i].coords)
	}
	// remove duplicate coordinates if words overlap
	const ids = allCoordinates.map(({ coordHash }) => coordHash);
	const filteredCoord = allCoordinates.filter(({ coordHash }, index) => !ids.includes(coordHash, index + 1))
	// group by columns
	const groupByColumn = filteredCoord.reduce((x, y) => {
		(x[y.x] = x[y.x] || []).push(y.y);
		return x;
	}, {});
	for (let i = 0; i < stage[i].length; i++) {
		if (i in groupByColumn) {
			// if column has any letters to remove
			let newColLength = stage.length - 1;
			for (let j = stage.length - 1; j >= 0; j--) {
				if (!groupByColumn[i].includes(j)) {
					// if row index has a word we want to clear then
					stageEmpty[newColLength][i] = stage[j][i]
					newColLength--
				}
			}
			for (newColLength; newColLength >= 0; newColLength--) {
				stageEmpty[newColLength][i] = ['Z', 'clear', ''];
			}
		}
		// if column has no letters to remove
		else {
			for (let x = 0; x < stage.length; x++) {
				stageEmpty[x][i] = stage[x][i]
			}
		}
	}
	return stageEmpty;
};

export const shiftDownIfBelowEmpty = (stage: StageData) => {
	const stageEmpty: StageData = Array(stage.length).fill(undefined).map(() => Array(stage[0].length));
	for (let x = 0; x < stage[0].length; x++) {
		let newColLength = stage.length - 1;
		for (let y = stage.length - 1; y >= 0; y--) {
			// if below cell is empty shift everything down
			if (stage[y][x][0] !== 'Z') {
				// if row index has a word we want to clear then
				stageEmpty[newColLength][x] = stage[y][x]
				newColLength--
			}
		}
		for (newColLength; newColLength >= 0; newColLength--) {
			stageEmpty[newColLength][x] = ['Z', 'clear', ''];
		}
	}
	return stageEmpty;
};