import React from 'react';
import { StyledRulesPopup } from './styles/StyledRulesPopup';
import 'reactjs-popup/dist/index.css';
import odor from '../img/odor.png';
import sweet from '../img/sweet.png';
import './style.css';
import question from '../img/question-mark-48.png';

export function RulesPopup() {

    return (
    <StyledRulesPopup trigger={<button className="PopupButton"> <img src={question} width='16px' height='16px'/></button>}  modal position="right center">
      <h3>Rewire your brain with Wodrix!</h3>
      <p><b>You start</b> as a villager with no coins and no vocabulary</p>
      <p><b>The goal</b> is to progress to a wealthy well-spoken pharaoh</p>
      <p><b>Move blocks to make words</b> from the board; any non-diagonal direction counts</p>
      <div className="row">
      <div className="column">
       <img src={sweet} width='100%'></img>
      </div>
      <div className="column">
      <img src={odor} width='100%'></img>
      </div>
    </div>
      <p></p>
      <p><b>Only true intellectuals will be able to find hidden words</b></p>
      <p>Good luck :D</p>
    </StyledRulesPopup>
    )
  }