import React from 'react';
import './App.css';
import { Wodrix } from './components/Wodrix';

function App() {
  return (
    <div className="App">
       <header>
      </header>
      <Wodrix />
    </div>
  );
}

export default App;




