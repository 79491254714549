import React from 'react';
import { TetrominoTypeId } from '../types/tetrominos';
import { Letter } from '../types/types';
import { StyledCell } from './styles/StyledCell';

export function Cell({ type, letter, color }: {
  type: TetrominoTypeId,
  letter: Letter,
  color: string,
}): JSX.Element {
  return (
    <StyledCell type={type} color={color}>{letter}</StyledCell>
  )
}

