import WORDS from '../words.json';
import { Word, WordsOnBoard } from '../types/types';

export const generateTwoThreePairs = (nLetters: number, wordsOnBoard: WordsOnBoard[]) => {
  // TODO - Run this once per word set - this logic doesn't need to run on each block
  const threeLettersSet = new Set<string>();
  const twoLetterEndsSet = new Set<string>();
  const twoLetterBeginningsSet = new Set<string>();
  let wordList: Word[] = [];
  for (let i = 0; i < wordsOnBoard.length; i++) {
    if (wordsOnBoard[i][1] === 'merged') {
      wordList.push(wordsOnBoard[i][0])
    }
  };

  for (let i = 0; i < wordList.length; i++) {
    const word = wordList[i];
    threeLettersSet.add(word.slice(0, 3));
    twoLetterBeginningsSet.add(word.slice(0, 2));
    twoLetterEndsSet.add(word.slice(-2));
  }
  const threeLetters = Array.from(threeLettersSet);
  const twoLetterEnds = Array.from(twoLetterEndsSet);
  const twoLetterBeginnings = Array.from(twoLetterBeginningsSet);

  if (nLetters === 3) {
    return threeLetters[Math.floor(Math.random() * threeLetters.length)];
  }
  else if (nLetters === 2) {
    return twoLetterEnds[Math.floor(Math.random() * twoLetterEnds.length)]
  }
  else {
    return twoLetterEnds[Math.floor(Math.random() * twoLetterEnds.length)] + twoLetterBeginnings[Math.floor(Math.random() * twoLetterBeginnings.length)]
  }
};

export const generateWordsOnBoard: () => WordsOnBoard[] = () => {
  const wordsBoard: WordsOnBoard[] = [];
  const newWords = WORDS.filter(word => word.length < 6)
  // TODO: Igor's code review: this is a bug, this may return the same word multiple times
  // due to `Math.floor(Math.random() * newWords.length)` potentially returning the same index,
  // while the likelihood of this happening is decreasing with the number of words in the vocabulary
  for (let i = 0; i < 7; i++) {
    const randomNewWord = newWords[Math.floor(Math.random() * newWords.length)];
    wordsBoard.push([randomNewWord, 'merged']);
  }
  return wordsBoard;
};