import WORDS from '../words.json';

// implements Trie structure to look up a word or a prefix
export class Trie {
  root: TrieNode;

  constructor() {
    this.root = new TrieNode(null);
  }
  /**
   * inserts a word into a trie
   */
  insert(word: string) {
    let node = this.root;
    for (let i = 0; i < word.length; i++) {
      if (!node.children[word[i]]) {
        node.children[word[i]] = new TrieNode(word[i]);
        //node.children[word[i]].parent = node;
      }
      node = node.children[word[i]];

      if (i === word.length - 1) {
        node.end = true;
      }
    }
  };
}

export class TrieNode {
  /**
   * the "key" value is the character in sequence
   */
  key: string | null;
  children: { [key: string]: TrieNode };
  end: boolean;

  constructor(key: string | null) {
    this.key = key;
    this.children = {};
    this.end = false;
  }
}

export const createTrie = () => {
  const trie = new Trie();
  for (let word in WORDS)
    trie.insert(WORDS[word]);
  return trie;
}

export function findInTrie(prefix: string, trie: Trie): number {
  let node = trie.root;
  for (let i = 0; i < prefix.length; i++) {
    if (node.children[prefix[i]]) {
      // checking prefix has words
      node = node.children[prefix[i]];
    } else {
      // prefix doesn't exist
      return -1;
    }
  }
  return +node.end; // if found the whole word returns 1, if prefix returns 0  
};
