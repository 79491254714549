import styled from 'styled-components';

interface Props {
  height: number;
  width: number;
};

export const StyledStage = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(${props => props.width}, 1fr);
  grid-template-rows: repeat(${props => props.height}, 1fr);
  align-self: stretch;
  align-items: normal;
  grid-gap: 0;
  background-color: #808080;
`;