import styled from 'styled-components';

interface Props {
  gameover: boolean;
}

export const StyledDisplay = styled.div<Props>`
  background-color: ${props => (props.gameover ? 'red' : '#F3CA20')};
  flex: ${props => (props.gameover ? '' : '1 1 0')};
  height: ${props => (props.gameover ? '' : '30px')};
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 6px;
  padding-bottom:6px;
  color: black;
  font-size: 15px; 
  word-wrap: break-word;
  white-space: pre-line;
`;

