import { Trie, findInTrie } from './Trie';
import { StageData, TSFixme, WordCoordinates, Coordinate } from '../types/types';

// only pass in column and row if found any letter in on the board (not 0)
export function bfs(stage: StageData, word: string, x: number, y: number, arr: (WordCoordinates)[], seen: TSFixme, coords: Coordinate[], trie: Trie) {
  // exit recursion if reached the end of the board or if cell is empty or not in trie
  if (x < 0 || y < 0 || x >= stage[0].length || y >= stage.length || stage[y][x][0] === 'Z' || findInTrie(word, trie) === -1) {
    return;
  }
  if (coords.some(el => el.coordHash === JSON.stringify([x, y]))) {
    return;
  }
  // append next letter to word
  word = word.concat(stage[y][x][2])
  if (findInTrie(word, trie) === 0 || (findInTrie(word, trie) === 1 && word.length === 4)) {
    coords.push({ x, y, coordHash: JSON.stringify([x, y]) })
    if (x + 1 < stage[0].length) {
      bfs(stage, word, x + 1, y, arr, seen, [...coords], trie)
    }
    if (x - 1 >= 0) {
      bfs(stage, word, x - 1, y, arr, seen, [...coords], trie)
    }
    if (y + 1 < stage.length) {
      bfs(stage, word, x, y + 1, arr, seen, [...coords], trie)
    }
    if (y - 1 >= 0) {
      bfs(stage, word, x, y - 1, arr, seen, [...coords], trie)
    }
  }
  if (findInTrie(word, trie) === 1) {
    // found the whole word in trie
    coords.push({ x, y, coordHash: JSON.stringify([x, y]) })
    const obj: WordCoordinates = { word, coords };
    const objHash = JSON.stringify(obj);
    if (seen[objHash] === undefined) {
      arr.push(obj)
      seen[objHash] = null;
    }
    return;
  }
  return;
}