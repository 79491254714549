export const Z: Tetromino = {
    shape: [['Z']],
    nextPieceShape: [
      ['Z', 'Z', 'Z'],
      ['Z', 'Z', 'Z'],
      ['Z', 'Z', 'Z'],
      ['Z', 'Z', 'Z']
    ],
    color: '255, 255, 240',
    nletters: 0,
  };
  
  export const I: Tetromino = {
    shape: [
      ['Z', 'I', 'Z', 'Z'],
      ['Z', 'I', 'Z', 'Z'],
      ['Z', 'I', 'Z', 'Z'],
      ['Z', 'I', 'Z', 'Z'],
    ],
    nextPieceShape: [
      ['I', 'Z'],
      ['I', 'Z'],
      ['I', 'Z'],
      ['I', 'Z']
    ],
    color: '80, 227, 230',
    nletters: 4,
  };
  export const J: Tetromino = {
    shape: [
      ['Z', 'Z', 'Z'],
      ['J', 'Z', 'Z'],
      ['J', 'J', 'Z'],
    ],
    nextPieceShape: [
      ['J', 'Z'],
      ['J', 'J'],
      ['Z', 'Z'],
      ['Z', 'Z']
    ],
    color: '36, 95, 223',
    nletters: 3,
  };
  
  export const L: Tetromino = {
    shape: [
      ['Z', 'Z', 'Z'],
      ['Z', 'L', 'Z'],
      ['Z', 'L', 'Z'],
    ],
    nextPieceShape: [
      ['L', 'Z'],
      ['L', 'Z'],
      ['Z', 'Z'],
      ['Z', 'Z']
    ],
    color: '223, 173, 36',
    nletters: 2,
  };
  
  export const O: Tetromino = {
    shape: [
      ['O', 'O'],
      ['O', 'O'],
    ],
    nextPieceShape: [
      ['O', 'O'],
      ['O', 'O'],
      ['Z', 'Z'],
      ['Z', 'Z']
    ],
    color: '223, 217, 36',
    nletters: 4,
  }
  
  export const S: Tetromino = {
    shape: [
      ['Z', 'Z', 'Z'],
      ['Z', 'S', 'Z'],
      ['Z', 'S', 'Z'],
    ],
    nextPieceShape: 
    [
      ['S', 'Z'],
      ['S', 'Z'],
      ['Z', 'Z'],
      ['Z', 'Z']
    ],
    color: '48, 211, 56',
    nletters: 2,
  }
  
  export const T: Tetromino = {
    shape: [
      ['Z', 'T', 'Z'],
      ['Z', 'T', 'Z'],
      ['Z', 'T', 'Z'],
    ],
    nextPieceShape: [
      ['T', 'Z'],
      ['T', 'Z'],
      ['T', 'Z'],
      ['Z', 'Z']
    ],
    color: '132, 61, 198',
    nletters: 3,
  }
  
  export const TETROMINOS = {
    Z,
    I,
    J,
    L,
    O,
    S,
    T,
  };
  
  export type TetrominoTypeId = keyof typeof TETROMINOS;
  
  export interface Tetromino {
    shape: TetrominoTypeId[][];
    nextPieceShape: TetrominoTypeId[][],
    color: string;
    nletters: number;
  }
  
  export const randomTetromino: () => Tetromino = () => {
    const tetrominoKeys = 'IJLOST';
    const randTetrominoKey: string = tetrominoKeys[Math.floor(Math.random() * tetrominoKeys.length)];
    const keyTyped = randTetrominoKey as keyof typeof TETROMINOS;
    return TETROMINOS[keyTyped]
  };