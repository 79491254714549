import { useEffect, useRef } from 'react';

// TODO: Igor's code review: seems correct, but want to revisit for correctness
export function useInterval(callback: () => void, delay: number|null): void {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const currentCallback = savedCallback.current;
      if (currentCallback)
        currentCallback();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
}