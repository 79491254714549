import { Signal, GameEngine, Word, WordsOnBoard, Score, ScoreUpdateType, Level, Player} from '../types/types';
import { drawTetrominoOnStage, flushStage, createStage, findWordMatchesOnStage, clearMatchedWords, shiftDownIfBelowEmpty } from './stageFunctions';
import { resetPlayer, updatePlayerPos, rotatePlayer, checkCollision } from './playerFunctions';
import { generateWordsOnBoard } from './wordLetterGeneration';
import { wordsToLetter, makeWordsFromLetters } from './other_funcs'

export function handleSignal(currentState: GameEngine['gameState'], signal: Signal): GameEngine['gameState'] {
	const readonlyState = Object.freeze(currentState);
	const resultState =
		signal.type === 'game.start' ? createInitialState(readonlyState) :
			signal.type === 'piece.left' ? handleHorizontalMove(readonlyState, -1) :
				signal.type === 'piece.right' ? handleHorizontalMove(readonlyState, 1) :
					signal.type === 'piece.rotate' ? handlePieceRotation(readonlyState) :
						signal.type === 'piece.drop' ? handlePieceDrop(readonlyState, 1) :
							signal.type === 'piece.dropAuto' ? handlePieceDrop(readonlyState, 1): 
								undefined;
	if (resultState === undefined)
		throw new Error(`Unrecognized or not implemented signal: ${JSON.stringify(signal)}.`);
	return resultState;
};

function createInitialState(currentState: GameEngine['gameState']): GameEngine['gameState'] {
	let newStage = createStage();
	const trie = currentState.trie;
	const wordsOnBoard = generateWordsOnBoard();
	const newPlayer = resetPlayer(wordsOnBoard);
	const nextPlayer = resetPlayer(wordsOnBoard);
	newStage = drawTetrominoOnStage(newPlayer, flushStage(newStage));
	return {
		stage: newStage,
		player: newPlayer,
		score: 0,
		words: new Set(),
		level: {level: 'villager', multiplier: 0.5},
		wordsOnBoard: wordsOnBoard,
		wordsFound: [''],
		gameOver: false,
		trie: trie,
		bonusWords: [],
		nextPlayer: nextPlayer,
	};
};

function handleHorizontalMove(currentState: GameEngine['gameState'], moveX: number): GameEngine['gameState'] {
	if (checkCollision(currentState.player, currentState.stage, { moveX, moveY: 0 })) {
		return currentState;
	}
	const newPlayer = updatePlayerPos(currentState.player, { x: moveX, y: 0 }, false);
	const newStage = drawTetrominoOnStage(newPlayer, flushStage(currentState.stage));
	const trie = currentState.trie;
	return {
		stage: newStage,
		player: newPlayer,
		score: currentState.score,
		words: currentState.words,
		level: currentState.level,
		wordsOnBoard: currentState.wordsOnBoard,
		wordsFound: currentState.wordsFound,
		gameOver: false,
		trie: trie,
		bonusWords: currentState.bonusWords,
		nextPlayer: currentState.nextPlayer
	};
};

function handlePieceRotation(currentState: GameEngine['gameState']): GameEngine['gameState'] {
	const newPlayer = rotatePlayer(currentState.player, currentState.stage, 1);
	const newStage = drawTetrominoOnStage(newPlayer, flushStage(currentState.stage));
	const trie = currentState.trie;
	return {
		stage: newStage,
		player: newPlayer,
		score: currentState.score,
		words: currentState.words,
		level: currentState.level,
		wordsOnBoard: currentState.wordsOnBoard,
		wordsFound: currentState.wordsFound,
		gameOver: false,
		trie: trie,
		bonusWords: currentState.bonusWords,
		nextPlayer: currentState.nextPlayer
	};
};

function handlePieceDrop(currentState: GameEngine['gameState'], moveY: number): GameEngine['gameState'] {
	const trie = currentState.trie;
	if (checkCollision(currentState.player, currentState.stage, { moveX: 0, moveY })) {
		if (currentState.player.pos.y < 1) {
			return endGame(currentState);
		}
		else {
			let newPlayer = currentState.player
			newPlayer.collided = true;
			let newState = {
				stage: currentState.stage,
				player: newPlayer,
				score: currentState.score,
				words: currentState.words,
				level: currentState.level,
				wordsOnBoard: currentState.wordsOnBoard,
				wordsFound: currentState.wordsFound,
				gameOver: false,
				trie: trie,
				bonusWords: currentState.bonusWords,
				nextPlayer: currentState.nextPlayer
			};
			newState.stage = drawTetrominoOnStage(newState.player, flushStage(newState.stage));
			return handlePieceLanded(newState);
		}
	}
	const newPlayer = updatePlayerPos(currentState.player, { x: 0, y: moveY }, false);
	const newStage = drawTetrominoOnStage(newPlayer, flushStage(currentState.stage));
	return {
		stage: newStage,
		player: newPlayer,
		score: currentState.score,
		words: currentState.words,
		level: currentState.level,
		wordsOnBoard: currentState.wordsOnBoard,
		wordsFound: currentState.wordsFound,
		gameOver: false,
		trie: trie,
		bonusWords: currentState.bonusWords,
		nextPlayer: currentState.nextPlayer
	};
};

function handlePieceLanded(currentState: GameEngine['gameState']): GameEngine['gameState'] {
	const trie = currentState.trie;
	const wordMatches = findWordMatchesOnStage(currentState.stage, trie);
	let wordsOnBoard: WordsOnBoard[] = structuredClone(currentState.wordsOnBoard);
	let clearedStage = currentState.stage
	let wordsFound = new Set<string>();
	let curScore = currentState.score;
	let totalWordsFound = structuredClone(currentState.words);
	let newLevel: Level = currentState.level;
	if (wordMatches.length > 0) {
		clearedStage = shiftDownIfBelowEmpty(clearMatchedWords(wordMatches, currentState.stage));
		// loop through all matched words
		for (let i = 0; i < wordMatches.length; i++) {
			// collect all found words
			let word: string = wordMatches[i].word
			totalWordsFound.add(word);
			wordsFound.add(word);
			// check if any of the words are on the board and mark as cleared
			let wordString: Word[] = wordsOnBoard.map(function(x) {
				return x[0];
			});
			let idx: number = wordString.indexOf(word);
			if (idx !== -1) {
				if (wordsOnBoard[idx][1] === 'merged') {
					wordsOnBoard[idx][1] = 'clear'
					curScore = updateScore(curScore, {type: 'boardWord'}, currentState.level)
				}
			} else {
				curScore = updateScore(curScore, {type: 'bonusWord'}, currentState.level)
			}
		}
		if (checkIfAllWordsOnBoardCleared(wordsOnBoard)) {
			curScore = updateScore(curScore, {type: 'wholeBoard'}, currentState.level)
			wordsOnBoard = generateWordsOnBoard();
			newLevel = updateLevelIfEligible(currentState.level, curScore);
		}
		else {
			newLevel = updateLevelIfEligible(currentState.level, curScore);
		}
	}
	const newPlayer = currentState.nextPlayer;
	const nextPlayer = resetPlayer(wordsOnBoard);
	const newStage = drawTetrominoOnStage(newPlayer, clearedStage);
	return {
		stage: newStage,
		player: newPlayer,
		score: curScore,
		words: totalWordsFound,
		level: newLevel,
		wordsOnBoard: wordsOnBoard,
		wordsFound: [...wordsFound],
		gameOver: false,
		trie: trie,
		bonusWords: currentState.bonusWords,
		nextPlayer: nextPlayer
	}
};

function endGame(currentState: GameEngine['gameState']): GameEngine['gameState'] {
	const newState = structuredClone(currentState);
	newState.gameOver = true;
	return newState;
};

function checkIfAllWordsOnBoardCleared(wordsOnBoard: WordsOnBoard[]): boolean {
	if (wordsOnBoard.filter((v) => v[1] === 'clear').length === 5) {
		return true;
	}
	return false;
};


export function updateScore(currScore: Score, updateType: ScoreUpdateType, curLevel: Level ): Score {
	const newScore: Score = 
		updateType.type === 'boardWord' ? 100*curLevel.multiplier :
		updateType.type === 'wholeBoard' ? 5000*curLevel.multiplier :
		updateType.type === 'bonusWord' ? 50*curLevel.multiplier :
		0
	return newScore + currScore;
};

export function updateLevelIfEligible(curLevel: Level, curScore: Score): Level {
	const newLevel: Level = 
		curLevel.level === 'villager' && curScore >= 3000 ? {level: 'craftsman', multiplier: 1} :
		curLevel.level === 'craftsman' && curScore >= 5000 ? {level: 'merchant', multiplier: 1.5} :
		curLevel.level === 'merchant' && curScore >= 20000? {level: 'scribe', multiplier: 2} :
		curLevel.level === 'scribe' && curScore >= 70000 ? {level: 'soldier', multiplier: 3.5} :
		curLevel.level === 'soldier' && curScore >= 120000  ? {level: 'vizier', multiplier: 6} :
		curLevel.level === 'vizier' && curScore >= 1000000 ? {level: 'pharaoh', multiplier: 10} :
		curLevel
	return newLevel;
};

function showBonusWords(wordsOnBoard: WordsOnBoard[]) {
	return makeWordsFromLetters(4, wordsToLetter(wordsOnBoard)).slice(0,20)
};