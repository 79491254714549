import React, { useEffect, useState } from 'react';
import { useGameEngine } from '../hooks/useGameEngine';
import { useInterval } from '../hooks/useInterval';
import ReactGA from "react-ga4";

//components
import { Display } from './Display';
import { Stage } from './Stage';
import { Button } from './Button';
import { RulesPopup } from './RulesPopup';
import { WodrixLogo } from './WodrixLogo';
import "./style.css";
import { MySwipeEvent, Signal } from '../types/types';
import { useHotkeys } from 'react-hotkeys-hook';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { NextTetromino } from './NextTetromino';


// Initialize ReactGA
ReactGA.initialize("G-438620897");


function signalWithAnalyticsEvent(func: (arg: Signal) => void, sig: Signal, options: UaEventOptions) {
  ReactGA.event(options);
  func(sig);
}


export function Wodrix() {
  const [swipeState, setSwipeState] = useState<MySwipeEvent | undefined>(undefined);
  const { gameState, sendSignal } = useGameEngine();
  const [dropTime, setDropTime] = useState<number|null>(null);
  const [gamePaused, setGamePaused] = useState<string>('Pause');
  const [gameStarted, setGameStart] = useState<boolean>(false);
  const { stage, score, words, level, wordsOnBoard, wordsFound, gameOver, nextPlayer } = gameState;

  // https://github.com/jaywcjlove/hotkeys-js/blob/master/src/var.js
  useHotkeys('a', () => { sendSignal({ type: 'piece.left' }); });
  useHotkeys('d', () => { sendSignal({ type: 'piece.right' }); });
  useHotkeys('s', () => { sendSignal({ type: 'piece.drop' }); });
  useHotkeys('w', () => { sendSignal({ type: 'piece.rotate' }); });
  useHotkeys('left', () => { sendSignal({ type: 'piece.left' }); });
  useHotkeys('right', () => { sendSignal({ type: 'piece.right' }); });
  useHotkeys('down', () => { sendSignal({ type: 'piece.drop' }); });
  useHotkeys('up', () => { sendSignal({ type: 'piece.rotate' }); });

  useInterval(() => {
    if (dropTime !== null) {
      sendSignal({ type: 'piece.dropAuto' });
    }
  }, dropTime);
  
  useEffect(() => {
    if (!gameStarted) {
      setDropTime(null);
    }
    else if (gameOver) {
      setDropTime(null);
    }
    else if (swipeState) {
      console.log(swipeState.direction);
      setDropTime(null);
      if (swipeState?.direction === 'left') {
        sendSignal({ type: 'piece.left' });
      } else if (swipeState?.direction === 'right') {
        sendSignal({ type: 'piece.right' });
      }
      else if (swipeState?.direction === 'down') {
        sendSignal({ type: 'piece.drop' });
      }
      else if (swipeState?.direction === 'rotate') {
        sendSignal({ type: 'piece.rotate' });
      }
      else if (swipeState?.direction === 'end') {
        setDropTime(1000);
      }
      setDropTime(1000);
    }
  },
    [
      swipeState,
      swipeState?.direction,
      sendSignal,
    ],
  )
  function pauseGame() {
    ReactGA.event({
      category: "playerEvent",
      action: "clickPause",
      label: dropTime === null ? "Pause" : "Unpause",
      nonInteraction: false,
    });
    if (dropTime == null) {
      setDropTime(1000);
      setGamePaused('Pause');
    } else {
      setDropTime(null);
      setGamePaused('Resume');
    }   
  };
  return (
    <div
      className="wodrix-container">
      <WodrixLogo />
      <div className="game-frame">
        <div className="main-screen" >
          <div className="stats-container">
            <Display gameover={false} text={`coins\n${score}`}/>
            <Display gameover={false} text={`vocabulary\n${words.size}`} />
            <Display gameover={false} text={`level\n${level.level}`} />
          </div>
          <Stage stage={stage} onSwipe={setSwipeState} />
          <div style={{gap: '10px', display: 'inline-flex', alignItems: 'flex-start'}}>
          <Button callback={() => { sendSignal({ type: 'game.start' }); setGameStart(true); setDropTime(1000)}} buttonName={'Start'}/>
          <Button callback={() => { pauseGame() }}  buttonName={gamePaused}/>
          </div>
          <div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9-ggYR6Hg0YXhmmgMFtSJftuii2yeje0flPusTMD8-RMQLA/viewform?embedded=true"
        target="_blank" rel="noopener noreferrer"
        style={{color: 'white'}}
      >
        Give Feedback - Help make Wodrix BIG!</a>
          </div>
        </div>
        <div className="Board">
        <div className="Check" style={{ width: 18, height: 18, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
        <RulesPopup/>
        </div>
          <p style={{ color: 'white', fontSize: '13px' }}>{5 - wordsOnBoard.filter(word => word[1] === 'clear').length} words to find</p>
          {gameOver ? (
            <Display gameover={gameOver} text="Game Over" />
          ) : (
            <div>
            <div className="Window" id="WindowId">
              <div className="Wordlist">
                {wordsOnBoard.map(function (x, i) {
                  if (x[1] === 'clear') return <p style={{ textDecorationLine: 'line-through'}} id={x[0]}>{x[0]}</p>;
                  else return <p id={x[0]}>{x[0]}</p>;
                })}</div>
            </div>
          <div style={{textAlign: 'left'}}>
          <a style={{color: 'white', marginBottom: '5px', marginTop: '5px'}}>Next:</a>
          <NextTetromino player={nextPlayer}/>
          </div>
            </div>
          )}
          <div className='WordsCleared' style={{ color: 'white', fontSize: '13px', wordWrap: 'break-word' }}>{wordsFound.map(function (x, i) {
            return <p key={x}>{x}</p>;
          })}</div>
        </div>
      </div>
    </div>
  )
}