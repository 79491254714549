import { Player } from "../types/types";
import { TETROMINOS, TetrominoTypeId } from '../types/tetrominos';
import { Cell } from './Cell';
import { NextTetrominoCell } from './NextTetrominoCell';
import { StyledNextTetromino } from "./styles/StyledNextTetromino";

export function NextTetromino({ player }: { player: Player }): JSX.Element {
    const output: JSX.Element[] = [];
    let tetrominoId: TetrominoTypeId = 'Z';
    let letters: string[] = [];
    player.tetromino.forEach((row, y) => {  
        row.forEach((value, x) => {
            if (value !== 'Z') {
                tetrominoId = value;
                letters.push(player.letter[y][x])
            }
        })
    })
    let letterIdx = 0;
    TETROMINOS[tetrominoId].nextPieceShape.forEach((row, y) => {
        row.forEach((value, x) => {
            if (value === 'Z') {
                output.push(<NextTetrominoCell
                    key={`${y},${x}`}
                    type={value}
                    letter={'Z'}
                    color={'0,0,0'}
                />);
            } else {
                output.push(<Cell
                    key={`${y},${x}`}
                    type={value}
                    letter={letters[letterIdx]}
                    color={TETROMINOS[value].color}
                />);
                letterIdx += 1;
            }
    });
    }); 
    return (
      <StyledNextTetromino
        width={TETROMINOS[tetrominoId].nextPieceShape[0].length}
        height={TETROMINOS[tetrominoId].nextPieceShape.length}
      >
        {output}
      </StyledNextTetromino>
    )
  }