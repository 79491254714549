import React, { useRef, useEffect }  from 'react';
import { TETROMINOS } from '../types/tetrominos';
import { StageData, MySwipeEvent } from '../types/types';
import { Cell } from './Cell';
import { StyledStage } from './styles/StyledStage';
import { useTouchEvent } from '../hooks/useTouchEvent';

export function Stage({ stage, onSwipe }: { stage: StageData, onSwipe?: (e: MySwipeEvent) => void }): JSX.Element {
  const output: JSX.Element[] = [];
  const containerRef = useRef<HTMLDivElement>(null);

  const touchEvent = useTouchEvent(
    containerRef,
    {
      sensitivityThresholdInPixels: {
        horizontal: 7,
        vertical: 10,
      },
      throttleDelay: 15,
      debugging: { tracing: 'off' },
    },
  );
  
  useEffect(
    () => {
      if (onSwipe) {
        if (touchEvent) {
          if (touchEvent.tapping) {
            onSwipe({
              direction: 'rotate',
            })
          }
          else if (touchEvent.swiping && touchEvent.dy !== 0) {
            onSwipe({
              direction: 'down',
            })
          }
          else if (touchEvent.swiping && touchEvent.dx !== 0) {
            onSwipe({
              direction: touchEvent.dx > 0 ? 'right' : 'left',
            })
          }
          
          else
            onSwipe({
              direction: 'end',
            });
        }
      }
    },
    [
      touchEvent,
      onSwipe,
    ],
  );

  for (let row = 0; row < stage.length; row++) {
    for (let col = 0; col < stage[0].length; col++) {
      const currentCell = stage[row][col];
      const [tetrominoTypeId, , letter] = currentCell;
      output.push(<Cell
        key={`${row},${col}`}
        type={tetrominoTypeId}
        letter={letter}
        color={TETROMINOS[tetrominoTypeId].color}
      />)
    }
  }
  return (
    <StyledStage
      width={stage[0].length}
      height={stage.length}
      ref={containerRef}
    >
      {output}
    </StyledStage>
  )
}