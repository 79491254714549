import { useThrottle } from '@uidotdev/usehooks';
import { useCallback, useEffect, useRef, useState } from 'react';

export type TSFixme = any;

export interface TouchEventState {
  swiping: boolean;
  dx: number;
  dy: number;
  tapping: boolean;
}

export interface UseTouchEventsOptions {
  sensitivityThresholdInPixels: {
    horizontal: number;
    vertical: number;
  };
  throttleDelay: number;
  debugging: {
    tracing: 'on' | 'off';
  },
}

export function useTouchEvent(
  targetRef: React.RefObject<HTMLDivElement>,
  useTouchEventOptions?: Partial<UseTouchEventsOptions>,
): TouchEventState {
  const options = mergeOptions(useTouchEventOptions, createDefaultOptions());

  const { log: logFn } = useCreateLogger(options.debugging);
  const log = useCallback(logFn, [logFn, options.debugging.tracing]);

  const [touchEventState, setTouchEventState] = useState<TouchEventState>({
    swiping: false,
    dx: 0,
    dy: 0,
    tapping: false,
  });

  const [, setLastPosition] = useState<{ x: number, y: number }>();
  const [moveDetected, setMoveDetected] = useState<boolean>(false);

  const touchStartHandler: (this: HTMLDivElement, ev: TouchEvent) => TSFixme =
    useCallback(
      (e) => {
        e.preventDefault() // Prevent text selection
        log(`${new Date().toISOString()} touchstart`, e);
        setLastPosition(getTouchPosition(e));
        setTouchEventState({ swiping: true, dx: 0, dy: 0, tapping: false});
      },
      [log],
    );

  const touchMoveHandler: (this: HTMLDivElement, ev: TouchEvent) => TSFixme =
    useCallback(
      (e) => {
        e.preventDefault() // Prevent text selection
        log(`${new Date().toISOString()} touchmove`, e);
        setMoveDetected(true);
        setLastPosition((prevPosition) => {
          if (!prevPosition) throw new Error('prevPosition is null');

          const newPosition = getTouchPosition(e);
          if (!newPosition) throw new Error('newPosition is null');

          const dx = newPosition.x - prevPosition.x;
          const dy = newPosition.y - prevPosition.y;
          const absoluteDx = Math.abs(dx);
          const absoluteDy = Math.abs(dy);

          if (absoluteDx >= options.sensitivityThresholdInPixels.horizontal)
            setTouchEventState({ swiping: true, dx, dy:0, tapping: false }) 
          else if (absoluteDy >= options.sensitivityThresholdInPixels.vertical)
            setTouchEventState({ swiping: true, dx:0, dy, tapping: false });
          return newPosition;
        });
      },
      [
        log,
        options.sensitivityThresholdInPixels.horizontal,
      ],
    );

  const touchEndHandler: (this: HTMLDivElement, ev: TouchEvent) => TSFixme =
    useCallback(
      (e) => {
        e.preventDefault() // Prevent text selection
        log(`${new Date().toISOString()} touchend`, e);
        if (moveDetected) {
          setLastPosition(getTouchPosition(e));
          setTouchEventState({ swiping: false, dx: 0, dy: 0, tapping: false });
          setMoveDetected(false);
        } else {
          setLastPosition(getTouchPosition(e));
          setTouchEventState({ swiping: true, dx: 0, dy: 0, tapping: true });
          setMoveDetected(false);
        } 
      },
      [log],
    );

  useEffect(
    () => {
      if (targetRef.current) {
        const container = targetRef.current;

        if (container) {
          container.addEventListener('touchstart', touchStartHandler);
          container.addEventListener('touchmove', touchMoveHandler);
          container.addEventListener('touchend', touchEndHandler);
        }

        return () => {
          if (container) {
            container.removeEventListener('touchstart', touchStartHandler);
            container.removeEventListener('touchmove', touchMoveHandler);
            container.removeEventListener('touchend', touchEndHandler);
          }
        };
      }
    },
    [targetRef, touchStartHandler, touchMoveHandler, touchEndHandler],
  );

  const touchEventStateToReport = useThrottle(touchEventState, options.throttleDelay);
  return Object.freeze(touchEventStateToReport);
}

function mergeOptions(
  providedOptions: Partial<UseTouchEventsOptions> | undefined,
  defaultOptions: Readonly<UseTouchEventsOptions>,
) {
  const optionsToUse: UseTouchEventsOptions = {
    sensitivityThresholdInPixels: {
      ...defaultOptions.sensitivityThresholdInPixels,
      ...providedOptions?.sensitivityThresholdInPixels,
    },
    debugging: {
      ...defaultOptions.debugging,
      ...providedOptions?.debugging,
    },
    throttleDelay: providedOptions?.throttleDelay || defaultOptions.throttleDelay,
  };
  return Object.freeze(optionsToUse);
}

function createDefaultOptions() {
  const defaultOptions: UseTouchEventsOptions = {
    sensitivityThresholdInPixels: {
      horizontal: 10,
      vertical: 10,
    },
    // TODO: double check and document, ensuring no throttle by default
    // @see https://usehooks.com/usethrottle
    throttleDelay: 1,
    debugging: {
      tracing: 'off',
    },
  };
  return Object.freeze(defaultOptions);
}

function useCreateLogger(debuggingOptions: UseTouchEventsOptions['debugging']): { log: typeof console.log } {
  return { log: debuggingOptions.tracing === 'on' ? console.log : () => undefined };
}

function getTouchPosition(e: TouchEvent) {
  if (e.touches.length <= 0) return undefined;
  return { x: e.touches[0].clientX, y: e.touches[0].clientY };
}